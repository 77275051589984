import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import { faHome, faUtensils, faSwimmer } from "@fortawesome/free-solid-svg-icons";

import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import AmenityElement from "./AmenityElement";

import "./Amenities.scss";

const Amenities = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <Row>
        <Col xs={12} md={4}>
          <AmenityElement
            name={frontmatter.genName}
            section={frontmatter.general}
            iconName={faHome}
          />
        </Col>
        <Col xs={12} md={4}>
          <AmenityElement
            name={frontmatter.kitName}
            section={frontmatter.kitchen}
            iconName={faUtensils}
          />
        </Col>
        <Col xs={12} md={4}>
          <AmenityElement
            name={frontmatter.outName}
            section={frontmatter.outdoor}
            iconName={faSwimmer}
          />
        </Col>
      </Row>
    </PageSection>
  );
};

Amenities.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Amenities.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Amenities;
