import React from "react";
import PropTypes from "prop-types";
import { Accordion, Card, Button, ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AmenityElement = (props) => {
  const { section, name, iconName } = props;
  const something = section.map((data) => {
    return <ListGroup.Item key={data.toString()}>{data}</ListGroup.Item>;
  });
  return (
    <Accordion defaultActiveKey="1">
      <Card
        style={{
          width: "inherit",
          background: "#f8f9fa",
          padding: 0,
          borderRadius: 0,
          textAlign: "center",
          marginBottom: "5px",
        }}
      >
        <Card.Header style={{ padding: 0, height: "70px", color: "red" }}>
          <Accordion.Toggle
            style={{
              backgroundColor: "transparent",
              border: 0,
              width: "100%",
              height: "inherit",
              padding: 0,
              color: "black",
              textDecoration: "none",
              marginBottom: "50px",
            }}
            as={Button}
            variant="link"
            eventKey="0"
          >
            <FontAwesomeIcon
              style={{
                width: "50px",
                height: "50px",
                marginBottom: "-15px",
                color: "black",
                marginRight: "10px",
              }}
              icon={iconName}
            />
            {name}
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <ListGroup className="timeline">
            <div>{something}</div>
          </ListGroup>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

AmenityElement.propTypes = {
  section: PropTypes.array,
  name: PropTypes.string,
  iconName: PropTypes.string,
};

AmenityElement.defaultProps = {
  section: null,
  name: null,
  iconName: null,
};

export default AmenityElement;
