import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row, Col } from "react-bootstrap";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import { SRLWrapper } from "simple-react-lightbox";
import Image from "components/Image";

import "./Portfolio.scss";


const Portfolio = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }


  const { anchor, header: rootHeader, subheader: rootSubHeader, gallery } = frontmatter;
  return (
    <PageSection className={clsx("portfolio-section", className)} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <Row>
        <Col>
            <SRLWrapper>
              <div className="grid-layout">
              {gallery.map(({imageFileName, imageFileNameDetail}) => (
                  <div key={imageFileName} >
                    <Image
                      className="image-layout"
                      fileName={imageFileName}
                      alt={imageFileNameDetail}
                    />
                  </div>
                )
              )} 
              </div>
            </SRLWrapper>
        </Col>
      </Row>

    </PageSection>
  );
};

Portfolio.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Portfolio.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Portfolio;
