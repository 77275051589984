import React from "react";
import PropTypes from "prop-types";

import Image from "components/Image";
import { Row } from "react-bootstrap";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import CardDeck from "react-bootstrap/CardDeck";
import Card from "react-bootstrap/Card";
import "./Reviews.scss";

const Reviews = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const {
    anchor,
    header: rootHeader,
    subheader: rootSubHeader,
    cards,
  } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <Row>
          <CardDeck>
            {cards.map((e) =>
              <Card key={e.header}>
                <Card.Header>
                  <Image className="mx-auto circle rounded-circle" fileName={e.imageFileName} alt={e.header} />
                </Card.Header>
                
                <Card.Body>
                  <Card.Title>{e.header}</Card.Title>
                  <Card.Text>
                    {e.subheader}
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <p className="xxl text-muted">{e.grade}</p>
                </Card.Footer>
              </Card>
            )}
          </CardDeck>
      </Row>
    </PageSection>
  );
};

Reviews.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Reviews.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Reviews;
