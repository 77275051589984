import React, { useState } from "react";
import PropTypes from "prop-types";

import { Modal, Row, Col, Form, Button } from "react-bootstrap";
import PageSection from "components/PageSection";
import Iframe from "react-iframe";
import emailjs from "emailjs-com";
import "./Contact.style.scss";

const Contact = ({ className, frontmatter }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  if (!frontmatter) {
    return null;
  }

  const mapStyle = {
    frameBorder: 0,
    borderColor: "white",
  };

  const { anchor, header, subheader, confirmTitle, confirmBody, confirmClose } = frontmatter;

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm("service_5lcojjd", "template_gmail", e.target, "user_VXT97RP0rx9XUo2cPEs70")
      .then
      /*         (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }, */
      ();
    e.target.reset();
  }

  return (
    <PageSection className={className} id={anchor}>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{confirmTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{confirmBody}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {confirmClose}
          </Button>
        </Modal.Footer>
      </Modal>

      <Row className="justify-content-center">
        <Col lg={8} className="text-center">
          <h2 className="mt-0">{header}</h2>
          <hr className="divider my-4" />
          <p className="text-muted mb-5">{subheader}</p>
        </Col>
      </Row>
      <Row>
        <Col lg={6} className="ml-auto text-center">
          <Iframe
            stlye={mapStyle}
            url="https://maps.google.com/maps?q=Lozica%2010,%20Prigradica&t=&z=13&ie=UTF8&iwloc=&output=embed"
            width="444px"
            height="444px"
            id="gmap_canvas"
            className="myClassname map-width"
            display="initial"
            position="relative"
          />
        </Col>
        <Col lg={6} className="mr-auto text-center">
          <Form onSubmit={sendEmail}>
            <Form.Control
              style={{ borderRadius: 0 }}
              name="userName"
              type="text"
              placeholder="Your Name"
            />
            <br />
            <Form.Control
              style={{ borderRadius: 0 }}
              name="userEmail"
              placeholder="Your Email"
              required
            />
            <br />
            <Form.Control style={{ borderRadius: 0 }} name="startDate" type="date" placeholder="" />
            <br />
            <Form.Control style={{ borderRadius: 0 }} name="endDate" type="date" placeholder="" />
            <br />
            <Form.Control
              style={{ borderRadius: 0 }}
              name="message"
              as="textarea"
              rows={5}
              placeholder="Your Message"
            />
            <br />
            <Button
              style={{
                background: "#0063DC",
                textAlign: "center",
                color: "white",
              }}
              onClick={handleShow}
              type="submit"
              value="Send"
              variant="primary"
            >
              Submit
            </Button>{" "}
          </Form>
        </Col>
      </Row>
    </PageSection>
  );
};

Contact.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Contact.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Contact;
